/************************************************************ Components ************************************************************/

/** Dropdown Menu **/
/* .discreet-dropdown class hides the border of the dropdown menu so it fits in line with text */
.dropdown.discreet-dropdown button {
	border: unset !important;
	margin-top: -5px;
	background: unset !important;
	box-shadow: unset;
	text-align: left;
}

.discreet-dropdown {
	margin-top: -4px;
}

/* #button.dropdown-item allows dropdown buttons to look the same as dropdown anchor (a) tags */
button.dropdown-item {
	background: inherit;
	font: inherit;
}

/* Overrides some styles which a button may try to override */
.dropdown-item {
	font-size: 0.875rem !important;
	line-height: 1.5 !important;
	padding: 0.7rem;
}

/** Accordian Card Elements **/
/* Accordian Container */
.accordian {
	min-height: 260px;
	display: flex !important;
	padding: 0 !important;
	padding-bottom: 8px !important;
	scroll-behavior: smooth;
	overflow-x: scroll;
	max-width: 100%;
}

.accordian.is-table {
	min-height: unset;
}

/** Navigation (Sidebar) Menu **/
/* Overrides some styles which a link within the list may try to override */
.menu-list a {
	padding: unset;
	align-items: center;
	display: flex !important;
}

/* Adds spacing between menu items, except top menu item in list */
.menu-list li:not(:first-child) {
	margin-top: 5px;
}

/* Overrides some styles which a button within the list may try to override */
.menu-list-item {
	margin: inherit !important;
	background-color: inherit !important;
	border: inherit !important;
	padding: inherit !important;
	font: inherit !important;
	color: inherit !important;
}

.menu-list a.active,
.tabs.is-toggle a.active {
	background-color: rgb(209, 170, 95) !important;
	border-color: rgb(209, 170, 95) !important;
	color: #fff !important;
}

/** Navigation (Mobile) Menu **/
.floating-mobile-nav {
	background-color: unset !important;
}

.floating-mobile-nav.navbar {
	min-height: unset;
}

.floating-mobile-nav .navbar-start {
	display: flex;
	align-items: baseline;
	flex-direction: row;
	justify-content: space-around;
}

.floating-mobile-nav .navbar-item.has-dropdown-up .navbar-dropdown {
	border-radius: 10px;
	border-top: none;
	bottom: 100%;
	box-shadow: 1px 0em 1em 1px
		hsla(
			var(--bulma-scheme-h),
			var(--bulma-scheme-s),
			var(--bulma-scheme-invert-l),
			0.1
		);
	top: auto;
	position: absolute;
	width: 300%;
	background-color: #2e333d;
	margin-bottom: 16px;
}

.floating-mobile-nav .navbar-item {
	font-size: 1em;
	color: #fff;
}

a.navbar-item:focus,
a.navbar-item:focus-within,
a.navbar-item:hover,
.navbar-link:focus,
.navbar-link:focus-within,
.navbar-link:hover {
	background-color: unset;
	font-weight: 900;
}

.floating-mobile-nav
	.navbar-dropdown
	.navbar-item:not(.is-active, .is-selected),
.floating-mobile-nav span.navbar-link {
	background-color: transparent;
	color: #fff;
}

a.navbar-item.active,
a.navbar-item.selected,
.navbar-link.active,
.navbar-link.selected {
	padding: 0px 30px;
	font-weight: 900;
	color: #d1aa5f;
}

/* Remove Underline from Tabs */
/* .floating-mobile-nav a.navbar-item {
	align-items: center;
	padding: 0.5em 1em;
}

.floating-mobile-nav .navbar-start {
	display: flex;
	justify-content: space-around;
}

.floating-mobile-nav span.navbar-link,
a.navbar-item {
	display: inline-flex;
} */

/* .floating-mobile-nav ul {
	border-bottom: unset !important;
}

.floating-mobile-nav li.is-active a {
	font-weight: 900 !important;
}

.floating-mobile-nav li.is-active a {
	color: rgb(209, 170, 95) !important;
}

.floating-mobile-nav li a {
	color: hsl(0, 0%, 96%) !important;
}

.floating-mobile-nav li:hover {
	font-weight: 600 !important;
} */

/** Box **/
/* Centre Align Box Content */
.box.selectable {
	min-width: 100%;
	justify-content: inherit;
	display: inline-flex;
	justify-content: center;
	text-align-last: center;
}

/** Cards **/
/* Change the background colour of the selected card */
.card.selected {
	background-color: #a1a1a108;
}

/* Adds spacing between stacked cards */
.card.selectable:not(:first-child) {
	margin-top: 20px;
}

/* Make The selected card and hovered cards have a darker shadow */
.card.selectable:hover,
.card.selected,
.box.selectable:hover,
.section.selectable:hover {
	box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
		0 0px 0 1px rgb(10 10 10 / 2%) !important;
}

/* Makes unselected cards have a lighter shadow */
.card.selectable,
.box.selectable,
.section.selectable {
	box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 4%),
		0 0px 0 1px rgb(10 10 10 / 2%) !important;
	transition: box-shadow 0.2s ease-in-out;
}

/** Form Fields **/

/* Text Area needs padding */
.control.has-icons-left .input,
.control.has-icons-left .select select,
.control.has-icons-left .textarea {
	padding-left: 2em;
}

/* .field eleemnts inside .builder container have more spacing */
.field:not(:last-child, :only-child, :nth-child(0)) {
	padding-bottom: 0.5rem !important;
	padding-top: 0.5rem !important;
}

/* .builder.create container has a smaller width and is all centre aligned */
form.builder.create {
	width: auto;
	margin: auto;
	text-align: center;
	max-width: 50vw;
	/* text-align-last: center; */
}

form.builder.contacts .field.is-horizontal:not(:last-child) {
	margin-bottom: 1rem !important;
	padding: 0;
}

form.builder.contacts .field.is-horizontal:is(:last-child) {
	margin-bottom: 3rem !important;
	padding: 0;
}

/* Ensures single fields are horizontally centred in a centre-aligned form */
form.builder .field.horizontally-center-one,
.field.horizontally-center-one {
	display: flex;
	justify-content: center;
	flex-direction: column;
	flex-basis: auto;
	align-items: center;
}

.field.horizontally-center-one .field-label {
	text-align: center !important;
}

/* For file uploads, allow fields with display-images class to render an image at a good size, overriding the usual max-height of the field */
div.display-image span.file-name {
	height: auto !important;
}
/* Horizontal Form Fields - Bigger space for Name and Description and Align Name and Description to Left */
.field.is-horizontal .field-label,
.field-label {
	flex-grow: 3 !important;
	text-align: left !important;
}

/* Horizontal Form Fields - Vertically Align fields to Centre of Container */
.field.is-horizontal {
	align-items: center !important;
}

/* Layover for Edit Website Buttons */
form .editorBtnsOver {
	position: sticky;
	bottom: 0px;
	border-radius: 5px;
	z-index: 999;
}

/* Range Slider - Remove margin from label to allow the slider to sit inline with other field types */
label.label.no-padding {
	margin: 0 !important;
}

/* Forces "File Name" on file upload to be the same length as the "File CTA" */
.file.is-boxed.has-name .file-name {
	min-width: 100%;
}

/** Card Component **/

/* Overrides some inherited styles when a card footer item is rendered as a button */
button.card-footer-item {
	background: inherit !important;
	border: inherit !important;
	margin: inherit !important;
	font: inherit !important;
}

/* Makes the button change background color on click */
.card-footer-item:active {
	background-color: #f9f9f9 !important;
}

/* Card Overlay */
.stat-overlay {
	border-radius: 10px;
	margin: 0px 5px 0px 5px;
	background-color: rgb(209, 170, 95);
}

/** Notifications **/
/* Make notification display in top-right corner */
.notification.popup {
	position: absolute;
	top: 40px;
	right: 40px;
	width: 300px;
}

/** Headings **/
/* Match button style to text */
h1 .button.is-text,
button.buttonAsLink.is-text.button,
a.buttonAsLink {
	padding: 0 !important;
	margin: 0;
	height: auto;
	font-size: inherit;
	text-decoration: inherit;
	background-color: none !important;
	color: unset !important;
	vertical-align: baseline !important;
}

button.buttonAsLink.is-text.button:hover,
a.buttonAsLink:hover {
	background: none !important;
}

/** Profile Picture Image **/
.image img.is-rounded {
	object-fit: cover;
	height: 100%;
	width: 100%;
}

/************************************************************ Content ***************************************************************/

/* Main Content Container */
.content-parent-container {
	margin: 0 1.5rem 1.5rem 1.5rem !important;
}

.content {
	border-radius: 5px;
	margin-top: 30px;
	background-color: #fff;
	margin-top: 30px;
	box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 4%),
		0 0px 0 1px rgb(10 10 10 / 2%);
}

.column {
	max-width: 100% !important;
}

/************************************************************ Stripe ****************************************************************/

.StripeElement--focus,
.StripeElement--webkit-autofill,
.StripeElement {
	padding: 0.75rem;
	background-color: #fff;
	border-radius: 5px;
	transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease,
		color 0.15s ease;
	transition-duration: 0.15s, 0.15s, 0.15s, 0.15s;
	transition-timing-function: ease, ease, ease, ease;
	transition-delay: 0s, 0s, 0s, 0s;
	transition-property: background, border, box-shadow, color;
	border: 1px solid #e6e6e6;
	box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%);
}

.StripeElement--focus {
	box-shadow: rgba(50, 50, 93, 0.15) 0 0 0 1px,
		rgba(50, 50, 93, 0.1) 0 2px 5px 0;
}

.StripeElement--invalid {
	border-color: #fa755a;
}

.StripeElement--webkit-autofill {
	-webkit-text-fill-color: #222;
}

/************************************************************ Notifications *************************************************************/
:root {
	--toastify-color-info: hsl(207, 61%, 53%) !important;
	--toastify-color-success: hsl(153, 53%, 53%) !important;
	--toastify-color-warning: hsl(44, 100%, 77%) !important;
	--toastify-color-error: hsl(348, 86%, 61%) !important;
	--toastify-font-family: inherit !important;
}
