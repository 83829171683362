@charset "utf-8";

// Dependancies
// /* Navigation Menu Overrides */
// $menu-item-active-background-color: $WDFA_Primary;

// Custom Variables
$WDFA_Primary: rgb(209, 170, 95);
$WDFA_Fonts: Figtree, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
	"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial",
	sans-serif;

// All Dependencies
@use "/node_modules/bulma/sass/utilities" with (
	$family-primary: $WDFA_Fonts,
	$primary: $WDFA_Primary
);
@use "/node_modules/bulma/sass/utilities/mixins";
@use "/node_modules/bulma/sass/base";
@use "/node_modules/bulma/sass/themes";
@use "/node_modules/bulma/sass/helpers";

// Layout
@use "/node_modules/bulma/sass/layout/section" with (
	$section-padding: 2rem 1rem,
	$section-padding-desktop: 2rem 1.5rem
);
@use "/node_modules/bulma/sass/grid/columns"; // replaces /columns
@use "/node_modules/bulma/sass/layout/container";
@use "/node_modules/bulma/sass/layout/media";
@use "/node_modules/bulma/sass/layout/level";

// Form
@use "/node_modules/bulma/sass/form" with (
		$input-disabled-border-color: hsl(0deg, 0%, 87%),
		// had !important
	);

// Elements
@use "/node_modules/bulma/sass/elements/button";
@use "/node_modules/bulma/sass/elements/icon";
@use "/node_modules/bulma/sass/elements/title";
@use "/node_modules/bulma/sass/elements/image";
@use "/node_modules/bulma/sass/elements/content";
@use "/node_modules/bulma/sass/elements/tag";
@use "/node_modules/bulma/sass/elements/notification";
@use "/node_modules/bulma/sass/elements/box";
@use "/node_modules/bulma/sass/elements/progress";
@use "/node_modules/bulma/sass/elements/table" with (
	$table-cell-border-width: unset,
	$table-cell-border-color: unset,
	$table-cell-border-style: unset,
	$table-head-cell-border-width: 0px 0px 0px 0px
);
@use "/node_modules/bulma/sass/elements/block";

// Components
@use "/node_modules/bulma/sass/components/dropdown";
@use "/node_modules/bulma/sass/components/menu"; // had $menu-item-active-background-color: $WDFA_Primary
@use "/node_modules/bulma/sass/components/navbar";
@use "/node_modules/bulma/sass/components/tabs";
@use "/node_modules/bulma/sass/components/card";
@use "/node_modules/bulma/sass/components/modal" with (
	// $modal-card-foot-border-top: 0px,
	// $modal-card-head-border-bottom: 0px,
	$modal-card-head-background-color: #fff,
	$modal-card-foot-radius: 10px,
	// above had !important
	$modal-card-head-padding: 20px 20px 20px 20px,
	$modal-card-body-padding: 10px 20px 0px 20px
);

// Import a Google Font
@import url("https://fonts.googleapis.com/css?family=Figtree&display=swap");

/* Form Field Changes for Gray Background */

.textarea,
.input {
	box-shadow: inset 0 0.0625em 0.125em rgb(10 10 10 / 5%) !important; // Seems to be being overridden for some reason...
}

/* Level Items */
// Allow elements to render underneath eachother
.level-left,
.level-right {
	display: block !important;
}

// Make sure elements wrap rather than overflow
.level-item {
	flex-shrink: unset !important;
}

/* Tags */
// Remove default padding for stacked tags
.tags:not(:last-child) {
	margin-bottom: unset !important;
}

/* Cards */
.media {
	justify-content: center;
}

/* Popup Modals */
.modal-card-body {
	margin-top: -1px;
}

.modal.is-active {
	z-index: 1000;
}

.buttons:last-child:is(.dialogue-buttons) {
	margin-bottom: unset;
}

/* Table */
tbody tr:nth-child(odd) {
	background-color: #f9f9f9 !important;
}
tbody td {
	vertical-align: middle !important;
}

//* Bulma CSS Imports *//

/** MOBILE AND TABLET **/
@include mixins.touch {
	// The App Container
	.columns.app-container {
		height: 100dvh !important; // Dynamic Vertical Height for all browsers that support it
		min-height: -webkit-fill-available; // For all browsers that don't support dvh
		height: calc(
			100vh - calc(100vh - 100%)
		); // For all browsers that don't support dvh
		overflow: hidden !important; //Keep all scrolling contained within other elements
		display: flex; // Ensure columns are flexable
		flex-direction: column; // Ensure columns flex vertically
	}

	// This object will float at the bottom of the page
	.float-top-on-mobile {
		position: sticky; // Set the object's relativity
		top: 0; // Set the object's position to top of page
		left: 0; // Set the object's position to start from left-to-right
		background-color: #fff; // Ensure the container is not transparent (overlays over other content)
		// box-shadow: 0.5em 0 1em -0.125em rgb(10 10 10 / 16%); // Show distance between this item and the ones behind it
		z-index: 10; // Ensure the container overlays over other content
		min-width: 100vw; // Ensure the container is full width
		max-width: 100vw; // Ensure the container is full width
		overflow-x: scroll; // Ensure the object can be horizontally scrolled
		overflow-y: hidden; // Ensure the object does not overflow vertically and mess up the viewport size
	}

	// Mobile Navigation
	.tabs.is-toggle li a {
		height: 8vh; // Set the height of the menu buttons to be good for finger tapping
	}

	// When there is a nav menu at the bottom of the page
	.column:has(.float-btm-on-mobile) {
		padding-bottom: 5vh !important; // Add extra padding at the bottom of the page to account for the menu height
	}

	// For the main content area
	.column.outer-content-container {
		overflow: auto; // Scroll when overflowing
		height: 100%; // Set page height
		padding-bottom: 15vh; // Add padding to bottom of the page
		flex: 1; // Add flex-basis
		max-width: 100%;
	}

	// // All Columns
	.column:not(.padding-required),
	.columns:not(.padding-required) {
		margin: 0 !important;
		padding: 0 !important; // Remove all the white spacing from columns for mobile
		padding-bottom: 1vh !important; // Ensure there is still some space at the bottom of the page
	}

	// Odd Columns
	.column:is(:nth-child(odd)):not(.padding-required) {
		padding-top: 1vh !important; // Potentially now redundant?
	}

	/* Make Containers Wider */
	.content-parent-container {
		margin: 0 !important;
	}

	.section {
		padding: 2rem 0.25rem;
	}

	/* Text Align */
	.title,
	.subtitle,
	label.label,
	p.help {
		text-align: center;
	}

	/* Form Fields Align */
	.field {
		justify-content: center !important;
	}

	.field.is-grouped.is-grouped-multiline {
		min-width: 100%;
	}

	.file {
		display: unset !important;
		width: 100%;
	}

	.field.has-addons .control,
	.field.has-addons .control .button {
		width: 100%;
		margin-top: 5px;
	}

	/* Button Groups Centre Align */
	.buttons {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		width: 100%;
		margin: auto;
	}

	.tags {
		justify-content: center;
	}

	form.builder.create {
		max-width: unset;
	}

	.field.is-grouped.is-grouped-multiline {
		margin-left: 3px !important;
	}
}

// Forms
/* Disabled Field Addon Border Fix */
.input:not(disabled) {
	z-index: 1;
}

.input[disabled] {
	z-index: 0;
}

// Add Flex Wrapping
@include mixins.mobile {
	.field-body {
		flex-flow: row wrap;
	}
}

@include mixins.desktop {
	.field-body {
		flex-wrap: wrap;
	}
}

// Table
/* because $table-cell-border does not exist */
.content {
	--bulma-content-table-cell-border: unset !important;
}

/* ensure tables take up 100% of available width */
.table {
	width: 100%;
}

/* Menu Dropwodnws */
/* Keyframes for fade up animation */
@keyframes fadeUpIn {
	0% {
		opacity: 0;
		transform: translateY(10px); /* Start slightly below */
	}
	100% {
		opacity: 1;
		transform: translateY(0); /* End at the original position */
	}
}

@keyframes fadeUpOut {
	0% {
		opacity: 1;
		transform: translateY(0); /* Start at the original position */
	}
	100% {
		opacity: 0;
		transform: translateY(10px); /* End slightly below */
	}
}

/* Apply the animations to the dropdown */
.navbar-item.has-dropdown .navbar-dropdown {
	display: none; /* Hidden by default */
	opacity: 0; /* Ensure the dropdown is invisible when not active */
	transform: translateY(10px); /* Start slightly below */
	transition: all 0.3s ease; /* Smooth transition for other properties */
}

.navbar-item.has-dropdown.is-active .navbar-dropdown {
	display: block; /* Display the dropdown when active */
	animation: fadeUpIn 0.3s forwards; /* Apply fade up in animation */
}

.navbar-item.has-dropdown:not(.is-active) .navbar-dropdown {
	display: block; /* Ensure the dropdown is visible during the fade up out animation */
	animation: fadeUpOut 0.3s forwards; /* Apply fade up out animation */
	pointer-events: none; /* Disable pointer events during the animation */
}

.navbar-item,
.navbar-link {
	align-items: center;
	display: flex;
}

.navbar-item.has-dropdown {
	align-items: stretch;
}

.field-body > .field:not(:last-child) {
	margin-inline-end: unset;
}
